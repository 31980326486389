import './App.css';
import Impressum from './views/Impressum';
import Navigation from './components/Navigation/Navigation';

import { BrowserRouter as Router, Route } from 'react-router-dom'
import Datenschutz from './views/Datenschutz';
import Home from './views/Home';
import ErrorPage from './views/ErrorPage';
import Hints from './views/blog/Hints';
import AntiAliasing from './views/blog/Anti-Aliasing';
import Tonwertzunahme from './views/blog/Tonwertzunahme';

function App() {
  return (
    <div className="body">
      <Router>
        <Route path='/' component={Navigation} />
        <Route path='/' exact component={Home} />
        <Route path='/Impressum' exact component={Impressum} />
        <Route path='/Datenschutz' exact component={Datenschutz} />
        <Route path='/Blog/Hints' exact component={Hints} />
        <Route path='/Blog/Anti-Aliasing' exact component={AntiAliasing} />
        <Route path='/Blog/Tonwertzunahme' exact component={Tonwertzunahme} />
        <Route path="/404" exact component={ErrorPage} />
      </Router>
    </div>

  );
}

export default App;
