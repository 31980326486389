import React from 'react';
import './NavigationPoint.css';


class NavigationPoint extends React.Component {
    render() {

        if (this.props.mobileOnly) {
            return(
                <div>
                    <a href={this.props.link} className="naviPointIcon"><img src={this.props.icon} alt={this.props.name}/>
                    {this.props.label}</a>
                    
                </div>
            );
        } else {
            return(
                <div>
                    <a href={this.props.link} className="naviPoint">{this.props.name}</a>
                    <a href={this.props.link} className="naviPointIcon"><img src={this.props.icon} alt={this.props.name}/>
                    {this.props.label}</a>
                    
                </div>
            );
        }
              
    }
}

export default NavigationPoint;