import React from 'react';
import './text.css';

class Datenschutz extends React.Component {
    render() {
        return (
            <div className="textBody">
                <div className="textText">
                    <h1>Datenschutz</h1>
                    <h2>
                        Datenschutz
                    </h2>
                    <p>
                        Wir haben diese Datenschutzerklärung (Fassung 10.02.2021-311260593) verfasst, um Ihnen gemäß der
                        Vorgaben der <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=311260593" rel="noreferrer" target="_blank" >Datenschutz-Grundverordnung (EU) 2016/679</a> zu 
                        erklären, welche Informationen wir sammeln,
                        wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
                    </p>
                    <p>
                        Datenschutzerklärungen klingen für gewöhnlich sehr technisch. Diese Version soll Ihnen hingegen die wichtigsten Dinge so einfach und klar wie 
                        möglich beschreiben. Soweit es möglich ist, werden technische Begriffe leserfreundlich erklärt. Außerdem möchten wir vermitteln, dass wir mit 
                        dieser Website nur dann Informationen sammeln und verwenden, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht 
                        möglich, wenn man möglichst knappe, technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich 
                        hoffe Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch 
                        nicht kannten.<br />
                        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen, 
                        oder uns 
                        einfach eine E-Mail zu schreiben. Unsere Kontaktdaten finden Sie im Impressum.
                    </p>
                    <h2>
                        Automatische Datenspeicherung
                    </h2>
                    <p>
                        Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Website. Diese 
                        gesammelten Daten sollten möglichst sparsam und nur mit Begründung gesammelt werden werden. Mit Website meinen wir übrigens die Gesamtheit 
                        aller Webseiten auf Ihrer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain 
                        meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
                    </p>
                    <p>
                        Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver – das ist der Computer auf dem diese Webseite gespeichert ist – 
                        aus Gründen der Betriebssicherheit, zur Erstellung von Zugriffsstatistik usw. in der Regel automatisch Daten wie
                    </p>
                    <ul>
                        <li>die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html/)</li>
                        <li>Browser und Browserversion (z. B. Chrome 87)</li>
                        <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
                        <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
                        <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
                        <li>Datum und Uhrzeit</li>
                        <li>in Dateien, den sogenannten Webserver-Logfiles</li>
                    </ul>
                    <p>
                        In der Regel werden diese Dateien zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht 
                        ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.
                    </p>
                    <p>
                        Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, 
                        aber wir geben Ihre Daten nicht weiter!
                    </p>
                    <h2>
                        Rechte laut Datenschutzgrundverordnung
                    </h2>
                    <p>
                        Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
                    </p>
                    <ul>
                        <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
                        <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
                        <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                        <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der 
                            Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
                        <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
                        <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu 
                            werden (Artikel 22 DSGVO)</li>
                    </ul>
                    <p>
                        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer 
                        Weise verletzt worden sind, können Sie sich an die <a href="https://www.bfdi.bund.de" rel="noreferrer" target="_blank">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.
                    </p>
                    <h2>
                        TLS-Verschlüsselung mit https
                    </h2>
                    <p>
                        TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für 
                        „sicheres Hypertext-Übertragungsprotokoll“) um Daten abhörsicher im Internet zu übertragen.
                    </p>
                    <p>
                        Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.
                    </p>
                    <p>
                        Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung (<a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=311260593" rel="noreferrer" target="_blank">Artikel 25 Absatz 1 DSGVO</a>). 
                        Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den 
                        Schutz vertraulicher Daten sicherstellen.
                    </p>
                    <p>
                        Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser links von der Internetadresse 
                        (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
                    </p>
                    <p>
                        Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute 
                        Links zu weiterführenden Informationen zu erhalten.
                    </p>
                    <h2>
                        Google Fonts Datenschutzerklärung
                    </h2>
                    <p>
                        Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das 
                        Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
                    </p>
                    <p>
                        Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in 
                        Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com 
                        angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto 
                        haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google 
                        erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung 
                        genau aussieht, werden wir uns noch im Detail ansehen.</p>

                    <h3>Was sind Google Fonts</h3>
                    <p>
                        Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=311260593" rel="noreferrer" target="_blank">Google</a> seinen Nutzern kostenlos zu Verfügung stellt.
                    </p>
                    <p>
                        Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. 
                        Beides sind freie Software-Lizenzen.
                    </p>

                    <h3>Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
                    <p>
                        Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren eigenen Server hochladen. 
                        Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das 
                        Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere 
                        Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche 
                        Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können 
                        teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts keine 
                        plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und 
                        funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). 
                        Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.
                    </p>


                    <h3>Welche Daten werden von Google gespeichert?</h3>
                    <p>
                        Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.
                    </p>
                    <p>
                        Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können<br />
                        Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
                    </p>


                    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
                    <p>
                        Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
                    </p>
                    <p>
                        Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
                    </p>


                    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
                    <p>
                        Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen 
                        Sie den Google-Support auf <a href="https://support.google.com/?hl=de&tid=311260593" rel="noreferrer" target="_blank">https://support.google.com/?hl=de&tid=311260593</a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
                    </p>
                    <p>
                        Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren 
                        Fragen finden Sie auf <a href="https://developers.google.com/fonts/faq?tid=311260593" rel="noreferrer" target="_blank">https://developers.google.com/fonts/faq?tid=311260593</a>. 
                        Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.
                    </p>
                    <p>
                        Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a href="https://www.google.com/intl/de/policies/privacy/" rel="noreferrer" target="_blank">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.
                    </p>
                    <p>
                        <br />
                    Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" rel="noreferrer" target="_blank">Datenschutz Generator</a> von AdSimple in Kooperation mit <a href="https://www.fashiongott.de" rel="noreferrer" target="_blank">fashiongott.de</a>.

                    </p>
                </div>
            </div>
        );
    }
}

export default Datenschutz

