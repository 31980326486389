import BlogFrame from '../../components/BlogFrame/BlogFrame';
import HeaderImage from '../../images/blog/antiAliasing/AntiAliasing.svg';

function AntiAliasing(props) {
    return (
        <BlogFrame
            headline="Anti Aliasing (Kantenglättung)"
            description="Mithilfe von Anti Aliasing kann der Treppeneffekt bei Schriften reduziert werden."
            keywords="Anti Aliasing Treppeneffekt Kantenglättung"
            alt="Raster, das den Vergleich mit und ohne AntiAliasing zeigt"
            headerImage={HeaderImage}
            text={
                <div>
                    <h3>Welches Problem wird?</h3>
                    <p>Durch die Darstellung auf Bildschirmen, werden alle dargestellten Elemente, abhängig von der Anzahl der vorhandenen Pixel,
                    in Raster aufgeteilt. Das Raster ist, wie auch die Pixel auf dem Bildschirm, horizontal sowie vertikal ausgerichtet. Durch
                    diesen Umstand sehen Linien die nur horizontal/vertikal verlaufen sehr scharf aus, während Diagonalen oder Kurven stufig aussehen
                    können. Dieser Effekt ist abhängig von der Auflösung des Bildschirms und tritt mit abnehmender Auflösung immer deutlicher auf. Das
                    Problem ist nun, dass z. B. Schriften bzw. Buchstaben eben häufig auch aus Linien bestehen, die nicht parallel zum Pixelraster
                    verlaufen und Buchstaben so teilweise sehr stufig/eckig aussehen können. Um dieses Problem in den Griff zu bekommen, setzt man
                    auf Anti Aliasing.</p>
                    <h3>Was passiert beim Anti Aliasing?</h3>
                    <p>Das Anti Aliasing reduziert den durch die Rasterdarstellung entstehenden Treppeneffekt, indem zusätzliche Pixel, an den Außenkanten 
                        der einzelnen Buchstaben, ergänzt werden. Die zu ergänzenden Pixel werden aus der Mischung, der Schriftfarbe und der Hintergrundfarbe, 
                        eingefärbt und bilden praktisch eine Art Verlauf von der Schrift hin zum Hintergrund. Der Verlauf sorgt dafür, dass das Auge die Stufen 
                        nicht mehr so stark wahrnimmt und die Kanten glatter wirken.</p>
                    <h3>Nachteil dieser Lösung</h3>
                    <p>Die Buchstaben bzw. das Schriftbild wirkt durch das Anti Aliasing zwar glatter, allerdings sorgen die abgestuften Pixel an den 
                        Außenkanten auch dafür, dass die Linien deutlich unschärfer aussehen und die Lesbarkeit infolgedessen nachlässt. Bei größeren 
                        Schriftgrößen kann dies meist vernachlässigt werden, bei Schriftgrößen unter 10px kann es hingegen zu deutlichen Einschränkungen 
                        bei der Lesbarkeit kommen. Bei kleinen Schriftgrößen sollte daher auf das Anti Aliasing verzichtet werden.</p>
                </div>
            }
        />
    );
};

export default AntiAliasing;