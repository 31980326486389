import { render } from '@testing-library/react';
import React from 'react';

import './Navigation.css';
import NavigationPoint from '../NavigationPoint/NavigationPoint';
import DatenschutzIcon from '../../icons/datenschutz.svg';
import ImpressumIcon from '../../icons/impressum.svg';
import HomeIcon from '../../icons/home.svg';

function Navigation(props) {
    return(
        <div className="naviContainer">
            <div className="logo">
                <a href="/" className="logoLink"><strong>paulblunt</strong></a>
            </div>
            <div className="naviPoints">
                <NavigationPoint name="Datenschutz" link="/datenschutz" icon={DatenschutzIcon} label="Datenschutz"/>
                <NavigationPoint name="Startseite" link="/" icon={HomeIcon} label="Startseite" mobileOnly="true"/>
                <NavigationPoint name="Impressum" link="/impressum" icon={ImpressumIcon} label="Impressum"/>
            </div>
        </div>
    );
}

export default Navigation;