import React from 'react';
import './BlogOverview.css';
import BlogCard from '../BlogCard/BlogCard';

import HintsImage from '../../images/blog/hints/hinting_ohne_und_mit_Hints.svg';
import AntiAliasingImage from '../../images/blog/antiAliasing/AntiAliasing.svg';
import TonwertzunahmeImage from '../../images/blog/tonwertzunahme/Tonwertzunahme.png';

class BlogOverview extends React.Component {
    render(){
        return(
            <div className="blogOverviewBackground">
                <h2>Artikel</h2>
                <div className="blogOverviewContainer">
                    <BlogCard link="/blog/Anti-Aliasing" image={AntiAliasingImage} headline="Anti Aliasing" summary="Mithilfe von Anti Aliasing kann der Treppeneffekt bei Schriften reduziert werden." />
                    <BlogCard link="/blog/Tonwertzunahme" image={TonwertzunahmeImage} headline="Tonwertzunahme" summary="Jeder der druckt, sollte wissen was die Tonwertzunahme ist" />
                    <BlogCard link="/blog/hints" image={HintsImage} headline="Hints" summary="Hints sind rechnerische Anweisungen, die die korrekte Darstellung von Schriften in verschieden Größen ermöglichen." />
                </div>
            </div>
        );
    }
}

export default BlogOverview; 