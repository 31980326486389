import React from 'react';
import './BlogCard.css';


class BlogCard extends React.Component {
    render(){
        return(
            <a className="blogCardContainer" href={this.props.link}>
                <div className="blogCardImage">
                    <img src={this.props.image} />
                </div>
                <div className="blogCardText">
                    <h3>{this.props.headline}</h3>
                    <p>{this.props.summary}</p>
                    <br/>
                    <a href={this.props.link}>Mehr erfahren</a>
                </div>

            </a>
        );
    }
}

export default BlogCard;