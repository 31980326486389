import React from 'react';
import './BlogFrame.css';
import MetaTags from 'react-meta-tags';

class BlogFrame extends React.Component {
    render() {
        return (
            <div className="blogArticleBody">
                <MetaTags>
                    <title>{this.props.headline}</title>
                    <meta name="description" content={this.props.description} />
                    <meta name="keywords" content={this.props.keywords} />
                    <meta property="og:title" content="paulblunt" />
                    <meta property="og:image" content={this.props.headerImage} />
                </MetaTags>
                <img src={this.props.headerImage} alt={this.props.alt} className="blogArticleHeader" />
                <div className="blogArticleText">
                    <h2>{this.props.headline}</h2>
                    {this.props.text}
                    <p className="blogArticleFooter">Diese Inhalte wurden nach bestem Wissen und Gewissen veröffentlicht.
                        Die Richtigkeit wird nicht garantiert und für Fehler wird keine Haftung übernommen.</p>
                </div>
            </div>
        );
    }
}

export default BlogFrame;