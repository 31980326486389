import BlogFrame from '../../components/BlogFrame/BlogFrame';
import HintImage from '../../images/blog/hints/hinting_ohne_und_mit_Hints.svg'

function Hints() {
    return (
        <BlogFrame
            headerImage={HintImage}
            description="Hints sind rechnerische Anweisungen, die die korrekte Darstellung von Schriften in verschieden Größen ermöglichen."
            keywords="Font Hinting Schriftgrößen Auflösung Problem"
            alt="Schematische Darstellung des Buchstaben H, mit und ohne Hinting"
            headline="Font Hinting"
            text={
                <div>
                    <p>Um die Charaktermerkmale einer Schrift auch bei kleinen Schriftgrößen oder geringen Bildschirmauflösung größtenteils 
                        beizubehalten, werden in Screen Outline Fonts (Vektorschriften), Zusatzinformationen bzw. rechnerische Anweisungen angegeben, 
                        welche man Hints nennt. Hints werden häufig (vor allem bei kostenlosen oder günstigen Schriften) automatisch durch Font Editoren 
                        generiert, können aber auch bei der optischen Evaluation des Schriftbildes in die Schriftdateien eingebettet werden, dies ist aber 
                        sehr aufwendig. Eine automatische Erstellung von Hints ist dabei meist nicht so gut wie die manuelle Erstellung von Hints. Diese 
                        Einbettung ist somit ein wesentliches Qualitätsmerkmal von Vektorschriften (PostScript® Fonts).</p>
                    <h3>Bedeutung bei der Schriftauswahl</h3>
                    <p>Da Hints auch zur Lesbarkeit von Schriftarten beitragen, wenn diese in kleinen Schriftgrößen (z.B. Kleingedrucktes) oder auf 
                        Bildschirmen mit einer geringen Auflösung dargestellt werden, sind sie auch ein wichtiges Kriterium bei der Auswahl von Webfonts. 
                        Es sollte bei der Schriftwahl daher vor allem auf das vorhanden sein und die Qualität der eingebetteten Hints geachtet werden.</p>
                    <h3>Funktion</h3>
                    <p>Bei kleineren Bildschirmen oder Bildschirmen mit einer geringen Auflösung (dies können z.B. alte Monitore sein, aber auch Bildschirme, 
                        bei denen eine hohe Auflösung auch heute einfach nicht benötigt wird z.B. Anzeigen am Bahnsteig etc.) reduziert sich die Anzahl der Pixel, 
                        die auf einer Fläche zur Verfügung steht, um Dinge wie in diesem Fall eine Schrift darzustellen. In diesen Fällen sorgen die Hints dafür, 
                        dass durch die rechnerischen Anweisungen, z.B. die Strichstärken und die Abstände bei weniger vorhandenen Pixeln nahezu gleichen bleiben bzw. 
                        so verändert werden, dass die Außenseiten exakt an den Pixelkanten ausgerichtet sind und die Buchstaben ihre für die Schriftart typische Form 
                        größtenteils bei behalten (desto kleiner die Schrift ist, desto weniger ist das festhalten an Charaktermerkmalen möglich). Die dabei erhalten 
                        bleibende Ausgewogenheit der Schrift, stellt in Folge auch die Lesbarkeit sicher.</p>
                    <h3>Hinweis</h3>
                    <p>Hints funktionieren meist sehr gut, können aber trotzdem nicht vollends verhindern, dass eine Schriftart bei kleinerer Darstellung an 
                        Charaktermerkmalen und Lesbarkeit einbüßt. Ab einem gewissen Punkt sind einfach nicht mehr genug Pixel da um z.B. Serifen ausdrucksvoll 
                        darzustellen. Dies führt dazu, dass sich die Schriften mit zunehmender Verkleinerung immer ähnlicher aussehen. Bei einfacheren Schriften 
                        fällt dies meist weniger auf als z.B. bei gebrochenen Schriften oder Schriften mit ausgeprägten Serifen.</p>
                    <h3>Lesetipps | Quellen | Infos</h3>
                    <ul>
                        <li><a href="https://de.wikipedia.org/wiki/Hint" rel="noreferrer" target="_blank" >Wikipedia: Hint</a></li>
                        <li><a href="https://kulturbanause.de/faq/font-hinting/" rel="noreferrer" target="_blank" >Kulturbanause: Was versteht man unter Font Hinting?</a></li>
                        <li><a href="https://www.typolexikon.de/font-hinting/" rel="noreferrer" target="_blank" >Typolexikon: Font Hinting</a></li>
                    </ul>
                </div>
            }
        />
    );
}

export default Hints;