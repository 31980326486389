import './text.css'

function ErrorPage() {
    return (
        <div className="textBody">
            <div className="textText">
                <h2>Wir haben die Seite leider nicht gefunden. </h2><a href="../">Ab zur Startseite!</a>
            </div>
        </div>
    );
}

export default ErrorPage