import React from 'react'
import './Placeholder.css';


/* function Placeholder() {
    return (
        <div className="placeholderContainer">
            <div className="placeholderLL">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <h1>paulblunt</h1>
            <div className="placeholderLR">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="placeholderLRA">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="placeholderLLA">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
} */

function Placeholder() {
    return (
        <header className="home">
            <h1>paulblunt</h1>
        </header>
    );
}

export default Placeholder