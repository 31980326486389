import React from 'react';
import './Home.css';
import Placeholder from '../components/Placeholder/Placeholder';
import BlogOverview from '../components/BlogOverview/BlogOverview';

class Home extends React.Component {
    render() {
        return (
            <div>
                <Placeholder />
                <BlogOverview />
            </div>

        );

    }
}

export default Home