import BlogFrame from '../../components/BlogFrame/BlogFrame';
import HeaderImage from '../../images/blog/tonwertzunahme/Tonwertzunahme.png';

function Tonwertzunahme(props) {
    return (
        <BlogFrame
            headline="Tonwertzunahme"
            description="Jeder der druckt, sollte wissen was die Tonwertzunahme ist."
            keywords="Tonwertzunahme Mitteltöne Farbzunahme dunkel Offsetdruck"
            alt="Bild einer Druckmaschine"
            headerImage={HeaderImage}
            text={
                <div>
                    <p>Wer schonmal mit Printmedien zu tun gehabt hat, weiß wahrscheinlich nur zu gut, dass die Dinge die hinten aus dem Drucker kommen, nicht immer so aussehen,
                    wie man sie in der Vorlage entworfen hat. Dies liegt daran, dass es beim Druck viele Einflussfaktoren und Effekte gibt, die beachtet werden müssen,
                         um ein sauberes Druckbild zu erreichen. Einer dieser Effekte ist die Tonwertzunahme.</p>

                    <h3>Wen die Tonwertzunahme betrifft</h3>
                    <p>Die Tonwertzunahme ist ein Effekt, der beim Druck entsteht. Sie ist somit nur für dich relevant, wenn du mit Medien arbeitest, die qualitativ gedruckt
                    werden sollen. Normal ist die Tonwertzunahme allerdings nur in der Druckvorstufe und beim endgültigen Druck relevant und wird somit meist von der
                    Druckerei übernommen. Wenn du also nicht in einer Druckerei oder Ähnlichem tätig bist, wird die Tonwertzunahme für dich eher irrelevant sein. Es
                    ist allerdings nie schlecht zu wissen, dass es diesen Effekt gibt und welche folgen er hat, um bei der Überprüfung eines Druckbildes darauf achten
                        zu können und mögliche Fehlerquellen ausfindig zu machen.</p>

                    <h3>Eine Tonwertzunahme erkennen</h3>
                    <p>Erkennbar ist die Tonwertzunahme vor allem dann, wenn sie im Druckprozess nicht beachtet wurde und ihr somit auch nicht entgegenwirkt wurde. 
                        Dies erkennt man daran, dass gedruckte Medien – abhängig von der Druckart und dem Rastertonwert – meist dunkler (selten auch heller) aussehen 
                        als im digitalen Entwurf. Dies kann meist nicht mit dem bloßen Auge erkannt werden, weshalb neben den Druckobjekten Kontrollstreifen gedruckt 
                        werden sollten, die dann mit einem Densitometer überprüft werden können.</p>

                    <h3>Was bei der Tonwertzunahme passiert</h3>
                    <p>Bei jeder Übertragung des Druckrasters, kommt es zu feinen Abweichungen der Druckrasterpunkte, wodurch die einzelnen Rasterpunkte meist etwas größer
                    werden als zuvor. Dies variiert natürlich je nach Druckart, Druckstoff usw. Bei indirekten Druckverfahren fällt dies häufig am stärksten auf, da es
                        hier zu einer zusätzlichen Übertragung des Druckrasters kommt.</p>
                    <p>Durch die größer werdenden Rasterpunkte steigt folglich auch der Rastertonwert (das prozentuale Verhältnis von be- und unbedruckter Fläche zueinander), 
                        wodurch vereinfacht gesagt mehr Farbe auf dem Druckstoff landet und das Bild folglich dunkler (selten heller) aussieht, als im Original.</p>
                    <p>Die Tonwertzunahme wird eigentlich immer im Verhältnis zu dem Rastertonwert gesetzt, da dieser die Tonwertzunahme maßgeblich beeinflusst. Bei 
                        einem Rastertonwert von 0 % sowie 100 %, liegt die Tonwertzunahme jeweils bei 0, zu den Mitteltönen hin nimmt sie jeweils zu und erreicht 
                        ungefähr dort – bei einem Rastertonwert von 50 % – ihr Maximum. Die Tonwertzunahme wird daher mithilfe der Druckkennlinie, die das Verhältnis 
                        der Tonwertzunahme zum Rastertonwert (inkl. erlaubter/genormter Abweichungen), grafisch darstellt.</p>

                    <h3>Warum die Rasterpunkte größer werden</h3>
                    <p>Die Vergrößerung der Rasterpunkt kann wie schon erwähnt, sehr viele Gründe haben, von denen ich hier nur eine Auswahl aufzählen möchte.</p>
                    <p>Einerseits beeinflusst das Druckverfahren die unterschiedlichen Größen der Rasterpunkte: Mit wie viel Druck wird gedruckt, wird direkt oder 
                        indirekt gedruckt, gibt es einen Quetschrand usw.? So kann hoher Druck z. B. dafür sorgen, dass die Farbe breiter in den Druckstoff gedrückt 
                        wird und die Rasterpunkte folglich größer werden als im ursprünglichen Raster. Des Weiteren wird die Rasterpunktgröße maßgeblich vom Druckstoff 
                        und seiner Saugfähigkeit beeinflusst: Wie ist das Papier beschichtet, wird natürliches Papier genutzt oder wird vielleicht sogar gar kein Papier 
                        verwendet? Ein saugfähigeres Papier kann z. B. dazu führen, dass die Farbe stärker/breiter in das Papier einzieht und die Rasterpunkte größer werden. 
                        Häufig ist der ausgefranste Rand der Rasterpunkte, der Hauptgrund warum diese größer werden/wirken.</p>

                    <h3>Wie verhindert man eine Tonwertzunahme</h3>
                    <p>Grundsätzlich kann man eine Tonwertzunahme nicht verhindern, da sie technischer Natur ist. Die Tonwertzunahme kann daher nur reduziert bzw. im
                    vorgeschriebenen Rahmen gehalten werden. Da die Ursache nicht verhindert werden kann, wird normal in der Druckvorstufe der Tonwertzunahme
                    entgegengewirkt, um am Schluss ein korrektes und zu dem Entwurf passendes Druckbild zu erreichen. Dies ist möglich, da es z. B. im Offsetdruck
                    vorher viele Probedrucke gibt, um die Druckmaschine so einzustellen/kennenzulernen, dass man eine nahezu gleichbleibende Tonwertzunahme von Druck
                    zu Druck zu erreichen kann, um mit diesen Werten dann in der Druckvorstufe der Tonwertzunahme entgegenzuwirken. Hierbei ist Erfahrung in dem Bereich
                        sehr hilfreich.</p>
                    <h3>Lesetipps | Quellen | Infos</h3>
                    <ul>
                        <li><a href="https://de.wikipedia.org/wiki/Tonwertzunahme#" rel="noreferrer" target="_blank" >Wikipedia: Tonwertzunahme</a></li>
                        <li><a href="https://themen.rainbowprint.de/was-ist-eine-tonwertzunahme/" rel="noreferrer" target="_blank" >Rainbowprint: Was ist eine Tonwertzunahme?</a></li>
                        <li><a href="https://unsplash.com/photos/Tzm3Oyu_6sk?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" rel="noreferrer" target="_blank" >Unsplash: Titelbild von Bank Phrom</a></li>
                        
                    </ul>
                </div>
            }
        />
    );
};

export default Tonwertzunahme;